<template>
  <footer :class="$style.TheFooter">
    <img
      src="/images/background/lines.svg"
      alt="lines"
      draggable="false"
      :class="$style.background"
    />
    <div :class="$style.wrapper">
      <div :class="$style.left">
        <NuxtLink to="/" :class="$style.logo">
          <SvgoIconLogo :class="$style.logotype" />
        </NuxtLink>
        <span :class="$style.txt">
          © Котлета {{ getYear() }}.<br />Все права защищены
        </span>
        <span :class="$style.mini">
          Сайт предназначен только для лиц старше 18 лет
        </span>
        <FooterMonitoringLinks :class="$style.desktop" />
      </div>
      <div :class="$style.right">
        <nav :class="$style.nav">
          <ul>
            <li v-for="item in menuItems" :key="item.title">
              <NuxtLink :to="item.link">
                {{ item.title }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <div :class="$style.info">
          <FooterSocial />
          <FooterMonitoringLinks :class="$style.mobile" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import FooterMonitoringLinks from "~/components/layout/footer/FooterMonitoringLinks.vue";
import FooterSocial from "~/components/layout/footer/FooterSocial.vue";

const { isLogged } = useUser();

const menuItems = [
  { title: "Правила", link: "/rules" },
  { title: "AML/KYC", link: "/aml" },
  { title: "Мониторингам", link: "/monitoring" },
  {
    title: "Пользователям",
    link: isLogged.value ? "/account/referral" : "/users",
  },
  { title: "Помощь", link: "/faq" },
  { title: "Новости", link: "/news" },
  { title: "Контакты", link: "/contacts" },
  { title: "Личный кабинет", link: "/account/dashboard" },
];

function getYear() {
  return new Date().getFullYear();
}
</script>

<style lang="scss" module>
.TheFooter {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: $footer-h;
  background: $base-0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    width: 100%;
    min-height: calc($footer-h - 1px);
    height: 100%;
    background: $base-900;
  }
}

.TheFooter,
.TheFooter::before {
  clip-path: polygon(
    0% 0%,
    6% 10%,
    20% 10%,
    25% 0%,
    45% 0%,
    50% 10%,
    100% 10%,
    100% 100%,
    0% 100%
  );

  @include respond-to(sm) {
    clip-path: polygon(
      0% 0%,
      6% 4%,
      20% 4%,
      25% 0%,
      45% 0%,
      50% 4%,
      100% 4%,
      100% 100%,
      0% 100%
    );
  }

  @include respond-to(xs) {
    clip-path: polygon(
      0% 0%,
      6% 2%,
      20% 2%,
      25% 0%,
      45% 0%,
      50% 2%,
      100% 2%,
      100% 100%,
      0% 100%
    );
  }
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.03;
}

.wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  padding: 5rem 2.4rem 0;
  width: 100%;
  height: 100%;

  @include respond-to(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding: 110px 24px 60px;
  }

  @include respond-to(xs) {
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 40px 16px 32px;
  }
}

.left {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @include respond-to(lg) {
    flex-shrink: unset;
  }
}

.logo {
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;
  margin-bottom: 2rem;
}

.logotype {
  max-width: 24.8rem;
  width: 100%;
  height: auto;

  @include respond-to(xs) {
    max-width: 108px;
  }
}

.txt {
  margin-bottom: 2rem;
  color: $base-500;
  font-size: 16px;
}

.mini {
  font-size: 12px;
  margin-bottom: 2rem;
  color: $base-500;
}

.right {
  display: flex;
  gap: 18rem;
  justify-content: space-between;

  @include respond-to(md) {
    gap: 40px;
  }

  @include respond-to(sm) {
    flex-direction: column-reverse;
  }

  @include respond-to(xs) {
    gap: 30px;
  }
}

.nav {
  display: none;
  ul {
    display: block;
    column-count: 2;
    column-gap: 100px;

    @include respond-to(lg) {
      column-gap: 50px;
    }

    @include respond-to(sm) {
      display: flex;
      flex-wrap: wrap;
      gap: 14px 28px;
    }

    @include respond-to(xs) {
      gap: 14px 16px;
    }

    li {
      width: fit-content;
      font-weight: 400;
      font-size: 2rem;
      line-height: 4rem;
      white-space: nowrap;
      color: #929292;

      @include respond-to(sm) {
        line-height: 32px;
      }

      @include respond-to(xs) {
        font-size: 16px;
      }
    }
  }
}

.info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;

  @include respond-to(xs) {
    flex-direction: column-reverse;
  }
}

.desktop {
  display: flex;

  @include respond-to(sm) {
    display: none;
  }
}

.mobile {
  display: none;

  @include respond-to(sm) {
    display: flex;
  }
}
</style>
