<template>
  <header :class="[$style.TheHeader, classes]">
    <div v-if="false" :class="$style.banner">
      <div :class="$style.wrap">
        <NuxtLink to="/contest" :class="$style.items">
          <div v-for="item in 6" :key="item" :class="$style.item">
            <SvgoIconUsers />
            <p>
              Оставляй отзыв на BestChange и участвуй в розыгрыше 10 тысяч
              рублей! - подробнее по <span>клику</span>
            </p>
          </div>
        </NuxtLink>
        <NuxtLink to="/contest" aria-hidden="true" :class="$style.items">
          <div v-for="item in 6" :key="item" :class="$style.item">
            <SvgoIconUsers />
            <p>
              Оставляй отзыв на BestChange и участвуй в розыгрыше 10 тысяч
              рублей! - подробнее по <span>клику</span>
            </p>
          </div>
        </NuxtLink>
      </div>
    </div>
    <div :class="$style.wrapper">
      <div :class="$style.left">
        <NuxtLink to="/" :class="$style.logo">
          <SvgoIconLogo :class="$style.logotype" />
        </NuxtLink>
        <CommonMainMenu
          :is-header="true"
          :menu-list="headerStore.filteredMenuList"
        />
      </div>
      <div :class="$style.right">
        <UiIndicatorVIndicator
          v-if="congestion"
          :status="congestion.load"
          :class="$style.indicator"
          :is-burger="false"
          >Загруженность сети btc</UiIndicatorVIndicator
        >
        <CommonToggleColorMode v-if="false" :class="$style.toggle" />

        <CommonTooltip v-if="isLogged" :text="email">
          <NuxtLink
            to="/account/dashboard"
            aria-labelledby="tooltip-label-date"
            :class="$style.auth"
          >
            <SvgoIconUser class="frame-icon" />
          </NuxtLink>
        </CommonTooltip>

        <NuxtLink v-else to="/login" :class="$style.auth">
          <SvgoIconUser class="frame-icon" />
          <span :class="$style.enter">Войти</span>
        </NuxtLink>
      </div>
      <button
        type="button"
        :class="$style.burger"
        @click.prevent="headerStore.toggleBurgerMenu(true)"
      >
        <SvgoIconBurger :class="$style.icon" />
      </button>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useHeaderStore } from "~/stores/headerStore";
import { useCalculator } from "~/composables/useCalculator";

const { congestion } = useCalculator();

const headerStore = useHeaderStore();

const isHidden = ref(false);
const start = ref(0);
const end = ref(0);
const isScrolling = ref(0);
const distance = ref(0);
const scrollPrev = ref(0);
const style = useCssModule();

const { email, isLogged } = useUser();

const classes = computed(() => ({
  [style._hidden]: isHidden.value,
}));

function handleScrollDown() {
  isHidden.value = true;
}

function handleScrollUp() {
  isHidden.value = false;
}

function checkVisibility() {
  if (!start.value) {
    start.value = window.pageYOffset;
  }

  window.clearTimeout(isScrolling.value);

  isScrolling.value = window.setTimeout(() => {
    end.value = window.pageYOffset;
    distance.value = end.value - start.value;

    if (end.value > 70 && end.value > scrollPrev.value) {
      handleScrollDown();
    } else {
      handleScrollUp();
    }

    scrollPrev.value = end.value;
  }, 20);
}

onMounted(() => {
  window.addEventListener("scroll", checkVisibility);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", checkVisibility);
});
</script>

<style lang="scss" module>
.tooltip {
  position: relative;
}

.text {
  position: absolute;
  width: 16rem;
  right: -30px;
  top: 0;
  border: 1px solid #fff;
  box-shadow: 0 1px 16px 0 rgb(0 0 0 / 8%);
  pointer-events: none;
  transition: 1s;
  padding: 0.8rem 1.6rem;
  font-size: 1.6rem;
  opacity: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltip:hover .text {
  opacity: 1;
  top: 30px;
  right: 0;
}

.enter {
  font-size: 1.6rem;
}

.TheHeader {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 16;
  width: 100%;
  height: $header-h;
  transform: translate(-50%, 0);
  transition: transform 0.5s ease-in-out;

  &._hidden {
    transform: translate(-50%, -180%);
  }
}

.banner {
  margin: auto;
  background-color: $brand-green;
}

.wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 20px;
}

.items {
  flex-shrink: 0;
  display: flex;
  gap: 20px;
  counter-reset: item;
  justify-content: space-around;
  min-width: 100%;
  animation: scroll 40s linear infinite;
}

.item {
  height: 3rem;
  display: flex;
  align-items: center;

  svg {
    margin: 0 3rem;
    flex-shrink: 0;
    width: 1.5rem;
    height: auto;
    color: $base-900;
  }

  span {
    text-decoration: underline;
  }

  p {
    padding-left: 1rem;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: $base-900;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8rem;
  padding: 0 2.4rem;
  height: 100%;
  width: 100%;
  background: $bg-color;
  border-bottom: 1px solid $color-border;

  @include respond-to(lg) {
    gap: 20px;
  }

  @include respond-to(sm) {
    padding: 0 16px;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 5rem;
  flex-shrink: 0;
  height: 100%;

  @include respond-to(md) {
    gap: 25px;
  }

  @include respond-to(sm) {
    width: 20%;
  }

  @include respond-to(xs) {
    width: unset;
  }
}

.logo {
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;
}

.logotype {
  max-width: 14rem;
  width: 100%;
  height: auto;

  @include respond-to(sm) {
    max-width: 100px;
  }
}

.right {
  display: flex;
  gap: 6rem;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;

  @include respond-to(lg) {
    gap: 20px;
  }

  @include respond-to(sm) {
    margin-left: unset;
    justify-content: center;
  }
}

.indicator {
  @include respond-to(xs) {
    display: none;
  }
}

.toggle {
  @include respond-to(sm) {
    display: none;
  }
}

.auth {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  @include respond-to(sm) {
    display: none;
  }
}

.burger {
  display: none;
  cursor: pointer;

  @include respond-to(sm) {
    display: flex;
    justify-content: flex-end;
    width: 20%;
  }

  @include respond-to(xs) {
    width: unset;
  }
}

.icon {
  width: 3.2rem;
  height: auto;
  color: $brand-green;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 20px));
  }
}
</style>
